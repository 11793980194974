import { z } from 'zod'

export type LoginUserForm = {
  email: string
  password: string
}
export const LoginFormSchema = z.object({
  email: z.string().email('El correo electrónico no es válido'),
  password: z.string().min(6, 'La contraseña debe tener al menos 6 caracteres'),
})

export type LoginForm = z.infer<typeof LoginFormSchema>

export const PhoneVerificationFormSchema = z.object({
  phone: z
    .string()
    .min(9, 'Longitud mínima de 9 dígitos')
    .max(9, 'Longitud máxima de 9 dígitos')
    .regex(/^[67]\d{8}$/, 'Debe ser un número válido'),
})

export type PhoneVerificationForm = z.infer<typeof PhoneVerificationFormSchema>

export const CodeFormSchema = z.object({
  code: z
    .string()
    .length(6, 'Longitud del código incorrecta')
    .regex(/^\d{6}$/, 'El código debe ser numérico entre 000000 y 999999'),
})

export type CodeForm = z.infer<typeof CodeFormSchema>
