'use client'
import { LoginForm, LoginFormSchema } from '../types/login-user-form'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { TextFieldPlatform } from 'ui/components/forms/text-field-platform'
import { ButtonPlatform } from 'ui/components/button-platform'
import { useState } from 'react'
import { pki, util } from 'node-forge'
import { signIn } from 'next-auth/react'
import { useLoginManagement } from './login-provider'
import { CredentialsError } from '../types/login-common-props'
import { getUserByEmail } from '../user/user-info'
import { useRouter } from 'next/navigation'
import { useTracking as useTrk } from '@repo/tracking/contexts/tracking'
import { TrackEvent, TrackEventValue } from '@repo/tracking/types/TrackEvents'
import { trackLogin } from '../verification/verification-service'

export function LoginPlatform() {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<LoginForm, any>({
    resolver: zodResolver(LoginFormSchema),
    reValidateMode: 'onChange',
  })
  const [{ status }, setStatusUser] = useState<{
    status: 'initial' | 'processing' | 'error'
  }>({ status: 'initial' })
  const router = useRouter()

  const { addTrack } = useTrk()
  const { setLoginData, pem, userAgent } = useLoginManagement((state) => state)

  const onSubmit = async (data: LoginForm) => {
    try {
      if (status === 'processing') return
      setStatusUser({ status: 'processing' })
      const publicKey = pki.publicKeyFromPem(pem)

      const res = await signIn('credentials', {
        email: data.email.trim().toLowerCase(),
        password: util.encode64(publicKey.encrypt(data.password)),
        redirect: false,
      })

      if (res?.error) {
        if (res?.error !== CredentialsError.TOTP_REQUIRED.toString()) {
          setError('password', { message: res.error })
          setStatusUser({ status: 'error' })
          return
        }

        addTrack({
          event: TrackEvent.LOGIN,
          value: TrackEventValue.LOGIN_TOTP,
        })
        const user = await getUserByEmail(data.email)

        if (user?.phoneVerified && user?.phone) {
          setLoginData({
            ...data,
            email: user?.email!,
            phone: user?.phone,
            isPhoneVerify: !!user?.phoneVerified,
          })
          router.push('/account/login/two-factor')
          return
        } else {
          setLoginData({
            ...data,
            email: user?.email!,
            phone: '',
            isPhoneVerify: false,
          })
          router.push('/account/login/phone-verify')
          return
        }
      }
      addTrack({
        event: TrackEvent.LOGIN,
        value: TrackEventValue.LOGIN_DIRECT,
      })

      trackLogin({ email: data.email, ...userAgent })
    } catch (ex) {
      setError('password', { message: (ex as Error).message })
      setStatusUser({ status: 'error' })
    }
  }

  return (
    <div className={'flex flex-col gap-6'}>
      <h1
        className={
          'text-xl sm:text-2xl text-pretty text-center text-platform-black-950'
        }>
        Accede a Formación Ninja y consigue tu plaza
      </h1>
      <form onSubmit={handleSubmit(onSubmit)} className={'flex flex-col gap-6'}>
        <fieldset className={'flex flex-col gap-2'}>
          <TextFieldPlatform
            label={'EMAIL'}
            type={'text'}
            {...register('email')}
            error={errors.email?.message}
          />
          <TextFieldPlatform
            label={'CONTRASEÑA'}
            type={'password'}
            {...register('password')}
            error={errors.password?.message}
          />
        </fieldset>
        <ButtonPlatform
          arrow={true}
          disabled={status === 'processing'}
          loading={status === 'processing'}
          full={true}>
          INICIAR SESIÓN
        </ButtonPlatform>
      </form>

      <div className={'flex flex-col gap-4 text-platform-black-500'}>
        <a
          href={'/account/email'}
          className={
            'text-sm text-pretty text-center hover:underline text-platform-blue-900'
          }>
          ¿Olvidaste tu contraseña?
        </a>
        <p className={'text-sm text-pretty text-center'}>
          ¿No tienes cuenta?{' '}
          <a
            href="https://formacion.ninja/precios"
            className={'text-platform-blue-900 hover:underline'}>
            Revisa nuestros planes
          </a>
        </p>
      </div>
    </div>
  )
}
