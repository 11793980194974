export type LoginCommonProps = {
  pemKey: string
}

export enum CredentialsError {
  TOTP_REQUIRED = 460,
  TOTP_INVALID = 461,
  INVALID_PASSWORD = 462,
  USER_NOT_FOUND = 463,
  DEFAULT = 500,
}
