'use client'
import React, { forwardRef, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'
import { HTMLMotionProps, motion } from 'framer-motion'
import { Loading } from './loading'
import { Arrow } from '../icons'

export type ButtonPlatformProps = HTMLMotionProps<'button'> & {
  extraProp?: string
  loading?: boolean
  arrow?: boolean
  full?: boolean
}

export const ButtonPlatform = forwardRef<
  HTMLButtonElement,
  ButtonPlatformProps
>(function Button(props, ref) {
  const {
    loading = false,
    disabled = false,
    arrow = false,
    full = false,
    children,
    ...btnProps
  } = props
  return (
    <motion.button
      whileTap={disabled ? undefined : { scale: 0.9 }}
      {...btnProps}
      className={twMerge(full && 'w-full')}
      ref={ref}>
      <div
        className={twMerge(
          'bg-platform-blue-950 rounded-full text-[0.813rem] px-8 py-3.5 text-white w-full group/btn-item font-medium tracking-wider',
          !disabled && !arrow && 'hover:bg-opacity-70',
          disabled && 'bg-platform-blue-100 text-platform-black-400',
          disabled && !loading && 'cursor-not-allowed',
          arrow && 'transition-all duration-300 ease-in-out'
        )}>
        <div
          className={twMerge(
            'flex items-center justify-center space-x-2 ',
            arrow &&
              !loading &&
              '-translate-x-4 transform transition-transform duration-300 ease-in-out group-hover/btn-item:translate-x-0'
          )}>
          {loading && <Loading />}
          {!loading && arrow && (
            <Arrow className="h-3 w-3 transition-opacity duration-300 ease-out opacity-0 stroke-white rotate-180 group-hover/btn-item:opacity-100 " />
          )}
          <span>{children as ReactNode}</span>
        </div>
      </div>
    </motion.button>
  )
})
